"use strict";
// adapt info from the custom oTracking.event dispatched by the cmp-client package
// and push it to Snowplow
window.addEventListener("oTracking.event", (e) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    console.log(e);
    if (((_b = (_a = e === null || e === void 0 ? void 0 : e.detail) === null || _a === void 0 ? void 0 : _a.component) === null || _b === void 0 ? void 0 : _b.subtype) === 'cmp') {
        window.dataLayer = window.dataLayer || [];
        //flush entities from previous event
        window.dataLayer.push({ 'event': 'flushEntities', 'event_payload': undefined });
        const cmpBanner = {
            category: 'CMP Banner',
            banner_name: (_d = (_c = e === null || e === void 0 ? void 0 : e.detail) === null || _c === void 0 ? void 0 : _c.component) === null || _d === void 0 ? void 0 : _d.name,
            action: ((_f = (_e = e === null || e === void 0 ? void 0 : e.detail) === null || _e === void 0 ? void 0 : _e.action.charAt(0)) === null || _f === void 0 ? void 0 : _f.toUpperCase()) + ((_h = (_g = e === null || e === void 0 ? void 0 : e.detail) === null || _g === void 0 ? void 0 : _g.action) === null || _h === void 0 ? void 0 : _h.slice(1)),
            banner_id: (_l = (_k = (_j = e === null || e === void 0 ? void 0 : e.detail) === null || _j === void 0 ? void 0 : _j.component) === null || _k === void 0 ? void 0 : _k.id) === null || _l === void 0 ? void 0 : _l.toString(),
            click_action: (_m = e === null || e === void 0 ? void 0 : e.detail) === null || _m === void 0 ? void 0 : _m.trigger_action
        };
        window.dataLayer.push({
            event: 'analytics_event',
            event_payload: {
                schema: 'iglu:com.financialtimes/cmp_banner/jsonschema/1-0-0',
                data: cmpBanner
            }
        });
        console.log(cmpBanner);
    }
}, false);
// a wrapper for the code that would execute for the Manage Cookies link in the footers
window._specialist_manage_cookies = () => {
    // @ts-ignore
    window._sp_.gdpr.loadPrivacyManagerModal(941275);
};
